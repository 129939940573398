import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { SAFESOUL_API_URL } from '@/constants/urls';

const Discord = ({ wallet, location, updateDiscordInfo }) => {
  const [discordName, setDiscordName] = useState(false);
  const [discordId, setDiscordId] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const disconnectDiscord = useCallback(async () => {
    try {
      await axios.post(`${SAFESOUL_API_URL}/api/discord/disconnect`, {
        wallet,
        id: discordId
      });

      setDiscordName(false);
      setDiscordId(false);
      updateDiscordInfo(false);
    } catch (error) {
      console.log(error);
    }
  }, [discordId, wallet]);

  const getDiscordInfo = useCallback(async () => {
    try {
      const { data } = await axios.post(`${SAFESOUL_API_URL}/api/discord/info`, {
        wallet
      });

      setLoaded(true);
      updateDiscordInfo(!!data?.discord_id);

      if (!data) return;

      setDiscordName(`@${data.discord_name}`);
      setDiscordId(data.discord_id)
    } catch (error) {
      console.log(error);
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet) {
      getDiscordInfo();
    } else {
      setDiscordName(false);
      setDiscordId(false);
      updateDiscordInfo(false);
    }
  }, [wallet]);

  if (!isLoaded) return <div className='h-16 flex bg-[#F3F3F4] rounded-lg'></div>;

  return (
    discordId ?
      <div className='flex bg-[#F3F3F4] rounded-lg px-4 py-3'>
        <div className='flex-1'>
          <div className='text-xs text-[#888888] font-medium'>Discord</div>
          <div className="text-lg font-medium">{discordName}</div>
        </div>

        <div>
          <button type='button' className='flex justify-center items-center p-3 h-full rounded-lg text-xs text-black/60 font-bold leading-none transition hover:text-black/60 hover:bg-[#E3E3E3]' onClick={disconnectDiscord}>Disconnect</button>
        </div>
      </div>
      :
      <div className='flex items-center rounded-lg p-4 bg-[#5869EA] text-white font-medium text-xs'>
        <div>Connect discord to get Patrol instructions, access tocommunity channels, receive alerts and many more</div>

        <div className="flex-shrink-0">
          <a href={`${SAFESOUL_API_URL}/discord/connect?wallet=${wallet}&redirect_to=${location.href}`} className='flex items-center justify-center w-28 h-10 rounded-lg text-white font-bold transition bg-[#000000]/0 hover:bg-[#000000]/20'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5447 6.99473C16.5249 6.53432 15.4313 6.1951 14.2879 6.00081C14.2671 5.99707 14.2463 6.00644 14.2356 6.02518C14.0949 6.27131 13.9392 6.59241 13.8301 6.84479C12.6003 6.66363 11.3768 6.66363 10.1722 6.84479C10.0631 6.5868 9.90166 6.27131 9.76038 6.02518C9.74966 6.00706 9.72886 5.99769 9.70803 6.00081C8.56527 6.19448 7.47171 6.53369 6.45129 6.99473C6.44246 6.99848 6.43488 7.00473 6.42986 7.01285C4.35559 10.0621 3.78737 13.0364 4.06612 15.9738C4.06738 15.9881 4.07558 16.0019 4.08693 16.0106C5.45547 16.9995 6.78114 17.5999 8.08219 17.9978C8.10301 18.0041 8.12507 17.9966 8.13832 17.9797C8.44608 17.5662 8.72043 17.1301 8.95565 16.6715C8.96953 16.6447 8.95628 16.6128 8.92791 16.6022C8.49275 16.4398 8.0784 16.2417 7.67982 16.0169C7.64829 15.9987 7.64577 15.9544 7.67477 15.9331C7.75865 15.8713 7.84255 15.8069 7.92264 15.742C7.93713 15.7301 7.95732 15.7276 7.97435 15.7351C10.5929 16.9114 13.4277 16.9114 16.0153 15.7351C16.0323 15.727 16.0525 15.7295 16.0677 15.7413C16.1478 15.8063 16.2316 15.8713 16.3161 15.9331C16.3451 15.9544 16.3433 15.9987 16.3117 16.0169C15.9131 16.2461 15.4988 16.4398 15.063 16.6016C15.0346 16.6122 15.022 16.6447 15.0359 16.6715C15.2762 17.1295 15.5505 17.5655 15.8526 17.9791C15.8652 17.9966 15.8879 18.0041 15.9087 17.9978C17.2161 17.5999 18.5417 16.9995 19.9103 16.0106C19.9223 16.0019 19.9298 15.9887 19.9311 15.9744C20.2647 12.5784 19.3723 9.62853 17.5655 7.01347C17.5611 7.00473 17.5535 6.99848 17.5447 6.99473ZM9.34668 14.1852C8.55833 14.1852 7.90876 13.473 7.90876 12.5984C7.90876 11.7238 8.54574 11.0117 9.34668 11.0117C10.1539 11.0117 10.7972 11.7301 10.7846 12.5984C10.7846 13.473 10.1476 14.1852 9.34668 14.1852ZM14.6632 14.1852C13.8748 14.1852 13.2253 13.473 13.2253 12.5984C13.2253 11.7238 13.8622 11.0117 14.6632 11.0117C15.4704 11.0117 16.1137 11.7301 16.1011 12.5984C16.1011 13.473 15.4704 14.1852 14.6632 14.1852Z" fill="white" />
            </svg>

            <span className='ml-2'>Connect</span>
          </a>
        </div>
      </div>
  );
};

export default Discord;
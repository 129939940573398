import {
  useContract,
  useProvider,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction
} from 'wagmi';
import { ethers } from 'ethers';

import { SAFESOUL_CONTRACT_ADDRESS } from '../constants/settings';

import SS_ABI from '../../static/assets/abi/safesoul_abi.json';
import { CHAIN_ID } from '../constants/settings';

const useSafeSoulContract = () => {
  const provider = useProvider({ chainId: CHAIN_ID });

  return useContract({
    address: SAFESOUL_CONTRACT_ADDRESS,
    abi: SS_ABI,
    signerOrProvider: provider
  });
};

export const useSafeSoulMint = (address, countToMint = 1, amountToMint = 1) => {
  const { config } = usePrepareContractWrite({
    address: SAFESOUL_CONTRACT_ADDRESS,
    abi: SS_ABI,
    functionName: 'mint',
    args: [address, countToMint],
    overrides: {
      value: ethers.utils.parseEther(amountToMint === 0 ? '0.002' : amountToMint.toString())
    },
  });

  const { data, write, isError } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  return { isLoading, isSuccess, isError, write, hash: data?.hash };
};

export const useSafeSoulPromote = () => {
  const { config } = usePrepareContractWrite({
    address: SAFESOUL_CONTRACT_ADDRESS,
    abi: SS_ABI,
    chainId: CHAIN_ID,
    functionName: 'promote',
    args: [],
  });

  const { data, write, isError } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  return { isLoading, isSuccess, isError, write, hash: data?.hash };
};

export const useSafeSoulClaim = (animals_count, signature = '-') => {
  console.log('animals_count', animals_count === 0 ? 1 : animals_count, signature);
  const { config } = usePrepareContractWrite({
    address: SAFESOUL_CONTRACT_ADDRESS,
    abi: SS_ABI,
    chainId: CHAIN_ID,
    functionName: 'claim',
    args: [animals_count === 0 ? 1 : animals_count, signature],
  });

  const { data, write, isError } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  return { isLoading, isSuccess, isError, write, hash: data?.hash };
};

export default useSafeSoulContract;
import { useState } from 'react';
import {
  useContract,
  useProvider,
} from 'wagmi';

import SSP_ABI from '../../static/assets/abi/safesoul_patrol_abi.json';
import { SAFESOUL_PATROL_CONTRACT_ADDRESS } from '../constants/settings';
import { useEffect } from 'react';

const useBoundedTokens = (wallet = null) => {
  const [count, setCount] = useState(0);

  const provider = useProvider();

  const contract = useContract({
    address: SAFESOUL_PATROL_CONTRACT_ADDRESS,
    abi: SSP_ABI,
    signerOrProvider: provider
  });

  useEffect(() => {
    if (!wallet) return setCount(0);

    contract
      .balanceOf(wallet)
      .then(res => {
        setCount(Number(res));
      })
      .catch(e => {
        console.error(e);
        setCount(0);
      });
  }, [wallet]);

  return count;
};

export default useBoundedTokens;
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { navigate } from 'gatsby';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

import MintLayout from '@/sections/mint/MintLayout';
import MintCard from '@/sections/mint/MintCard';
import Wallet from '@/sections/mint/Wallet';
import Discord from '@/sections/mint/Discord';

import useBoundedTokens from '@/hooks/useBoundedTokens';
import useSafeSoulContract, { useSafeSoulPromote } from '@/hooks/useSafeSoulContract';

import { CHAIN_ID } from '@/constants/settings';

const ActivateToken = ({ location }) => {
  const { chain } = useNetwork()
  const { switchNetwork, error } = useSwitchNetwork();

  const [countMintedTokens, setMintedTokensInfo] = useState(0);
  const [isDiscordConnected, setDiscordConnnected] = useState(false);

  const [isStartTransaction, setStartTransaction] = useState(false);

  const { address } = useAccount();

  const ssContract = useSafeSoulContract();
  const countBoundTokens = useBoundedTokens(address);

  const {
    isSuccess,
    isError,
    write
  } = useSafeSoulPromote();

  const isDisableProcess = useMemo(() =>
    countBoundTokens > 0 || countMintedTokens < 1 || !isDiscordConnected,
    [countBoundTokens, countMintedTokens, isDiscordConnected]
  );

  useEffect(() => {
    if (chain?.id !== CHAIN_ID && switchNetwork) {
      switchNetwork(CHAIN_ID);
    }
  }, [chain, switchNetwork]);

  const handleOwnTokens = useCallback(async () => {
    if (!address) return;

    try {
      const result = await ssContract.balanceOf(address, 1);

      setMintedTokensInfo(Number(result));
    } catch (e) {
      console.error(e);
    }
  }, [address]);

  useEffect(() => {
    handleOwnTokens && handleOwnTokens();
  }, [handleOwnTokens]);

  const activateToken = useCallback(async () => {
    if (!address || isDisableProcess) return;

    setStartTransaction(true);

    write();
  }, [address, write, isDisableProcess]);

  useEffect(() => {
    if (isSuccess) {

      navigate('/mint-activate-success');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.error('isError', isError);

      setStartTransaction(false);
    }
  }, [isError, setStartTransaction]);

  return (
    <MintLayout>
      <div className="min-h-screen">
        <MintCard title="Activate token">
          <Wallet />

          <div className="mt-6">
            <Discord
              wallet={address}
              location={location}
              updateDiscordInfo={setDiscordConnnected}
            />
          </div>
        </MintCard>

        <div className="mt-2">
          <MintCard>
            <div className="text-center font-medium">
              <p>Please note: After confirming the transaction you will burn one</p>
              <p>SafeSoul token and receive a non-transferable token</p>
            </div>
          </MintCard>
        </div>

        <div className="mt-2">
          <button
            type='button'
            onClick={activateToken}
            className={classnames(
              "flex items-center justify-center w-full rounded-lg py-5 px-10 font-bold transition bg-[#C2E900] hover:bg-[#C2E900]/80",
              {
                "opacity-50 cursor-not-allowed pointer-events-none": isDisableProcess || isStartTransaction,
              }
            )}
          >
            {isStartTransaction ?
              <span className='mx-auto'>Activation in progress <span className='inline-block animate-blink animation-delay-600'>.</span> <span className='inline-block animate-blink'>.</span> <span className='inline-block animate-blink animation-delay-300'>.</span></span>
              : <span>Activate</span>
            }
          </button>
        </div>
      </div>
    </MintLayout >
  );
};

export default ActivateToken;